import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Button, Container, Modal, ModalBody, ModalFooter } from "reactstrap";
import styled from "styled-components";
import useScreenSize from "../utils/screenSize";
import MobileHeader from "components/layouts/mobileHeader";

type MobileTemplateProps = {
  children?: React.ReactNode;
};

const MobileTemplate: FC<MobileTemplateProps> = ({ children }) => {
  const { t } = useTranslation();
  const size = useScreenSize();
  const dispatch = useDispatch();

  return (
    <Main>
      <MobileHeader
        onClickMenu={() => {
          window.scrollTo(0, 0);
        }}
      />
      <Container
        style={{ marginTop: "10px", paddingLeft: 0, paddingRight: 0 }}
        fluid
      >
        <div className="d-flex h-100">
          <Content style={{ paddingTop: "10%" }}>
            <div>{children}</div>
          </Content>
        </div>
      </Container>
    </Main>
  );
};

const Main = styled.div`
  background-color: #f5f5f5;
  display: flex;
  height: 100%;
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
`;

const Content = styled.div`
  flex: 1;
  display:flex;
  justify-content:center;
  @media (max-width: 990px) {
    margin-left: 0;
  }
  @media (max-width: 414px) {
    padding: 0;
  }
`;

export default MobileTemplate;
